import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumbs, Layout, Hero, Container, StatusLabel } from '@components'
import BlockWrapper from '@pageBuilder/wrapper'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Project = ({ data, pageContext }) => {
  const {
    moduleArea,
    seoMetaTags,
    title,
    heroImage,
    heroImageMobile,
    statusLabel
  } = data.page

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Hero image={heroImage} mobileImage={heroImageMobile} />
      <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} slim />
      <Container center>
        <h1>{title}</h1>
        <StatusLabel text={statusLabel} />
      </Container>
      <BlockWrapper locale={pageContext.locale} blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query projectById($id: String!) {
    page: datoCmsProject(id: { eq: $id }) {
      id
      path
      slug
      title
      statusLabel
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      heroImageMobile: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "600", fit: "crop", w: "750", q: 60 }
        )
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsProjectInfo {
          ...ProjectInfo
        }
        ... on DatoCmsSlider {
          ...Slider
        }
        ... on DatoCmsVideo {
          ...Video
        }
        ... on DatoCmsPartnersSlider {
          id
          paddingBottom
          paddingTop
          background
          partners {
            description
            logo {
              gatsbyImageData(
                layout: FULL_WIDTH
                imgixParams: { fit: "crop", h: "300", w: "500", q: 60 }
              )
            }
            title
          }
          __typename
        }
      }
    }
  }
`
export default Project
